import * as React from "react";
import { Contact } from "../components/contact/contact";
import Layout from "../components/layout/layout";
import { StaticImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";
import {
  container,
  flex,
  grid,
  column,
  alignCenter,
  staticImage,
} from "./../components/layout/layout.module.scss";

import { about, branding, portrait, navbar } from "./about.module.scss";

import { NavBar } from "../components/navbar/navbar";

const metaData = {
  author: "Andrew Winters",
  title: "About | Andrew Winters Design",
  description:
    "We design engaging, attractive websites that guarantee to build trust with your potential customers from first contact.",
  url: "https://www.andrewwintersdesign.co.nz/about", // No trailing slash allowed!
  image:
    "https://firebasestorage.googleapis.com/v0/b/awd-portfolio.appspot.com/o/preview.jpg?alt=media&token=4e3ef30b-4d18-4802-a144-f72b66a7ebee", // Path to the image placed in the 'static' folder, in the project's root directory.
  og: {
    siteName: "About | Andrew Winters Design",
    description:
      "Stand out with a professional website, blog, or online store.",
  },
};

const AboutPage = () => {
  return (
    <Layout
      pageTitle={metaData.title}
      ogTitle={metaData.og.siteName}
      ogDescription={metaData.og.description}
      description={metaData.description}
      image={metaData.image}
    >
      <section className={`${navbar}`}>
        <NavBar />
      </section>
      <section className={`${about}`}>
        <motion.div
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 100 }}
          transition={{ type: "ease", stiffness: 200, duration: 0.2 }}
          className={`${container} ${flex} ${column} ${alignCenter}`}
        >
          <h1 className={`${branding}`}>What do we do?</h1>
          <div className={`${grid}  ${alignCenter} ${branding}`}>
            <StaticImage
              src="https://firebasestorage.googleapis.com/v0/b/awd-portfolio.appspot.com/o/design.png?alt=media&token=ba68913d-5276-427c-ae21-52432fca09a2"
              alt=""
              className={`${staticImage}`}
            />
            <div>
              <h2>Design</h2>
              <p>
                We design engaging, attractive websites that guarantee to build
                trust with your potential customers from first contact.
                <br />
                <br />
                Your website will be the definitive expression of your
                businesses personality and values.
              </p>
            </div>
          </div>
        </motion.div>
        <div className={`${container} ${flex} ${column} ${alignCenter}`}>
          <div className={`${grid}  ${alignCenter} `}>
            <motion.div
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 100 }}
              transition={{
                type: "ease",
                stiffness: 200,
                duration: 0.2,
                delay: 0.1,
              }}
            >
              <h2>Develop</h2>
              <p>
                You can rely on our professional webdev skills to build your
                website from the ground up. Use our experience developing for
                Wordpress, Wix, Webflow, Squarespace, and HTML/CSS/Javascript.
                <br /> <br />
                Whether you want a simple website for your blog, or a complete
                e-commerce solution for your online store, with Andrew Winters
                Design you'll get all the functionality you need.
              </p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 100 }}
              transition={{
                type: "ease",
                stiffness: 200,
                duration: 0.2,
              }}
            >
              <StaticImage
                src="https://firebasestorage.googleapis.com/v0/b/awd-portfolio.appspot.com/o/develop.png?alt=media&token=23c92575-83d5-494d-b1f2-abe09286a338"
                alt=""
                className={`${staticImage}`}
              />
            </motion.div>
          </div>
        </div>
      </section>

      <section>
        <motion.div
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 100 }}
          transition={{ type: "ease", stiffness: 200, duration: 0.2 }}
          className={`${container} ${flex} ${column} ${alignCenter}`}
        >
          <div>
            <h2>About AWD.</h2>
            <p>
              Here at Andrew Winters Design, we have been helping small to
              medium business and not-for profits build engaging, attractive
              websites to build their online brand.
            </p>

            <p>
              We are based in Auckland, New Zealand, and work with people all
              over the world. We design with function in mind, and live by the
              phrase "Keep it simple, stupid".
            </p>
            <p>
              We'd love to help you build an online presence for your
              small/medium business, not-for-profit organisation, or your
              personal brand.
            </p>
            <div className={` ${flex} ${alignCenter}`}>
              <StaticImage
                src="https://firebasestorage.googleapis.com/v0/b/awd-portfolio.appspot.com/o/avatar.png?alt=media&token=c1c4189c-bacb-42b1-9151-0fd48561b573"
                alt=""
                className={`${staticImage} `}
                width={64}
                height={64}
              />
              <div className={`${portrait}`}>
                <label>
                  <b>Andrew Winters</b>
                </label>
                <br />
                <label>Founder | Andrew Winters Design</label>
              </div>
            </div>
          </div>
        </motion.div>
      </section>
      <Contact />
    </Layout>
  );
};

export default AboutPage;
